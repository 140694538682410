/*common css*/
.w-100 {
  width: 100%;
}

/* a:hover {
    text-decoration: none;
} */

.bt-header a.nav-link:hover {
  color: #0451be !important;
}

/* body {
    font-family: 'Poppins', sans-serif;
} */

.hide {
  display: none;
}

.style-ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.tooltipp {
  position: relative;
}

.tooltipp:before,
.tooltipp:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.for-mobile {
  display: none;
}

.tooltipp:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.75);
  border-left: 6px solid transparent;
  content: "";
  height: 0;
  top: 20px;
  left: 20px;
  width: 0;
}

.tooltipp:before {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  color: #fff;
  content: attr(data-title);
  font-size: 14px;
  padding: 6px 10px;
  top: 26px;
  white-space: nowrap;
}

.tooltipp.expand:before {
  transform: scale3d(0.2, 0.2, 1);
  transition: all 0.2s ease-in-out;
}

.tooltipp.expand:after {
  transform: translate3d(0, 6px, 0);
  transition: all 0.2s ease-in-out;
}

.tooltipp.expand:hover:before,
.tooltipp.expand:hover:after {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.tooltipp.expand:hover:after {
  transition: all 0.2s 0.1s ease-in-out;
}

/* fade */
.tooltipp.fade:after,
.tooltipp.fade:before {
  transform: translate3d(0, -10px, 0);
  transition: all 0.15s ease-in-out;
}

.tooltipp.fade:hover:after,
.tooltipp.fade:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/*header css*/

/* body {
    padding-top: 121px;
} */

.fixed-header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 100;
  top: 0px;
}

.header-bar .navbar {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 0px;
}

.header-bar-checkout .navbar {
  padding-right: 0px !important;
}

.header-bar {
  border-bottom: 2px solid #ff3201 !important;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
}

.logo {
  width: 130px;
}
.logo-bs {
  width: 275px;
}

.callbox {
  padding: 0px 15px;
}

.bt-header {
  border-top: 1px solid #ccc;
  padding: 0px 15px;
}

.bt-header .nav {
  height: 46px;
  margin-left: -3px;
}

.bt-header .nav-link {
  color: #353535;
  font-size: 13px;
  padding: 14px 5px;
  font-weight: 400;
  letter-spacing: -0.8px;
}

.right-tp-header ul {
  display: flex;
  list-style: none;
  margin-bottom: 0px;
}

.right-tp-header ul .search-block {
  position: relative;
}

.right-tp-header ul .search-block input {
  border-radius: 55px;
  border: 2px solid #7ba5e4;
  background: #fff;
  width: 279px;
  height: 40px;
  color: #4b7dc8;
  font-size: 14px;
  padding-left: 35px;
  line-height: 40px;
}

.right-tp-header ul .search-block input::placeholder {
  color: #4b7dc8;
}

.right-tp-header ul .search-block {
  position: relative;
}

.right-tp-header ul .search-block .custom-search-bar {
  border-radius: 55px;
  border: 2px solid #d3d3d3;
  font-weight: 400;
  background: #fff;
  width: 279px;
  height: 40px;
  color: #4b7dc8;
  font-size: 12px;
  padding-left: 35px;
  line-height: 40px;
}

.custom-search-bar div:first-child {
  margin-top: -8px;
}

.nav-col {
  padding-right: 0px;
  padding-left: 0px;
}

.right-tp-header ul .search-block i {
  position: absolute;
  color: #5e5e5e;
  top: 14px;
  left: 15px;
  font-size: 13px;
}

.right-tp-header ul li {
  padding-right: 15px;
}

.cr-location {
  width: 239px;
  position: relative;
  height: 40px;
  border-radius: 55px;
  border: 2px solid #d3d3d3;
  line-height: 35px;
  padding-left: 20px;
}

.right-tp-header ul li:last-child {
  padding-right: 0px;
}

.cr-location strong {
  color: #0b0b0b;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 87%;
  display: inline-block;
}

.cr-location span {
  position: absolute;
  color: #5e5e5e;
  font-size: 12px;
  font-style: normal;
  top: -9px;
  background: #fff;
  /* height: 27px; */
  padding: 3px 6px;
  display: block;
  line-height: 9px;
  left: 14px;
}

.cr-location i {
  color: #c8c8c8;
  font-size: 13px;
  position: absolute;
  right: 15px;
  top: 12px;
}

.ad-more-car .dropdown-menu {
  right: 0px !important;
  width: 350px !important;
  left: auto !important;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 0px;
}

.ad-more-car .dropdown-menu .yp-car-listing {
  max-height: 70vh;
  overflow-y: scroll;
}

.ad-more-car .dropdown-menu:before {
  content: "";
  position: absolute;
  top: -15px;
  right: 25px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}

.yp-car-listing ul {
  list-style: none;
  margin: 0px;
  padding: 0px 10px;
  display: block;
}

.yp-car-listing ul li {
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  height: 75px;
}

.yp-car-listing ul li:last-child {
  border-bottom: 1px dashed #ccd;
}

.yp-car-listing li i {
  position: absolute;
  right: 16px;
  color: #6883a1;
  font-size: 20px;
  top: 27px;
}

.yp-car-listing ul img {
  width: 98px;
}

.active-car {
  background: #ebf4ff;
}

.image-rg-cr h2 {
  font-size: 15px;
  color: #253c57;
  font-weight: 600;
  padding-top: 6px;
}

.image-rg-cr h5 {
  font-size: 13px;
  color: #595970;
}

.image-rg-cr h5 span {
  color: #8d8d8d;
}

.car-mod-action {
  background: white;
  position: sticky;
  bottom: 0px;
  text-align: center;
  padding: 10px 10px 3px;
  display: flex;
  gap: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.car-mod-action .adcar {
  border-radius: 50px;
  font-size: 13px;
  color: #fff;
  background: #f38c29;
  width: 50%;
}

.car-mod-action .mgrcar {
  background: #0451be;
  color: #fff;
  font-size: 13px;
  border-radius: 50px;
  width: 50%;
  padding: 10px !important;
}

.ad-car-dropdown {
  border-radius: 55px;
  border: 2px solid #0451be;
  background: #0451be;
  width: 191px;
  height: 40px;
  padding: 0px 20px;
  line-height: 35px;
  position: relative;
}

.glare-animation {
  position: relative;
  background: #52a515;
  overflow: hidden;
  border-color: #52a515;
  width: 135px;
}
@keyframes shine {
  0% {
    left: -100px;
  }
  20% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.glare-animation:before {
  content: "";
  position: absolute;
  width: 160px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 2s infinite linear; /* Animation */
}
.ad-car-dropdown strong {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}

.ad-car-dropdown i {
  color: #fff;
  font-size: 13px;
  position: absolute;
  right: 15px;
  top: 12px;
}

.right-action-nav ul {
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  height: 46px;
}

.right-action-nav ul li a {
  padding: 4px 10px;
  display: block;
  line-height: 39px;
}

.right-action-nav ul li {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin-right: -1px;
}

.right-action-nav-checkout ul li {
  border: 0px;
  margin-right: -1px;
}

.cred-action a {
  color: #2f2e2e;
}

.cred-action a span {
  display: inline-block;
  padding-left: 3px;
}

.cred-action a i {
  display: inline-block;
}

.workshop-link a {
  color: #ff3201;
  font-weight: 500;
  font-size: 12px;
}

.db-layer span {
  font-size: 11px;
  color: #7f8398;
  line-height: 14px;
  padding-top: 7px;
  padding-left: 7px;
  text-transform: uppercase;
  font-weight: 500;
}

.db-layer strong {
  color: #ff3201;
  font-weight: 700;
  display: block;
}

.db-layer a {
  display: flex !important;
}

.db-layer img {
  width: 35px;
  height: auto !important;
}

.menu-bar-right i {
  right: 0px;
  color: #8d8f92;
  font-size: 21px;
  padding-left: 14px;
  padding-top: 10px;
}

.db-layer a:active,
.db-layer a:focus {
  text-decoration: none !important;
}

.menu-bar-right img {
  height: max-content;
  padding-top: 5px;
}

.menu-bar-right strong {
  color: #000;
  width: 62px;
}

.car-loc-dropdown {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.4);
  border: 0px;
  border-radius: 10px;
  width: 400px;
}

.car-loc-dropdown:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 25px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}

.right-drop-elm .dropdown-menu {
  right: 0px;
  left: auto;
}

.right-drop-elm .car-loc-dropdown:before {
  right: 25px;
  left: auto;
}

.outer-cr-sl-skin {
  padding: 5px 16px;
}

.location-dr-bl h5 {
  color: #aeaeae;

  font-size: 15px;

  font-weight: 500;
}

.location-dr-input {
  position: relative;
}

.location-dr-input .gps-icon,
.location-dr-input .cross-icon {
  position: absolute;
  top: 6px;
}

.location-dr-input .gps-icon {
  right: 39px;
}

.location-dr-input .cross-icon {
  right: 13px;
  padding-top: 3px;
}

.location-dr-input input {
  font-size: 14px;
  padding-right: 50px;
  height: 43px;
}

.location-dr-action {
  padding: 12px 0px;
  border-bottom: 1px solid #ccc;
}

.sv-add {
  border-radius: 55px;
  border: 1px solid #0451be;
  background: #eef6ff;
  color: 286BBA;
  color: #0451be;
  padding: 4px 10px;
  font-size: 12px;
}

.saved-lc-block {
  border-radius: 10px;
  background: #eaf1f5;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding: 0px 15px;
}

.search-suggestion {
  position: absolute;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.4);
  width: 279px;
  border-radius: 4px;
  padding: 10px 0px;
  z-index: 1;
  top: 56px;
}

.search-suggestion ul {
  height: 300px;
  overflow-y: scroll;
}
/* width */
.search-suggestion ul::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.search-suggestion ul::-webkit-scrollbar-track {
  background: #f3f4ff;
}

/* Handle */
.search-suggestion ul::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.search-suggestion ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.search-suggestion ul {
  display: block;
}

.search-suggestion ul li {
  padding: 10px;
  font-size: 14px;
}

.search-suggestion ul li:hover {
  background: #f4f4f4;
}

.drop-d-n {
  display: flex;
  width: 100%;
  padding: 16px 0px;
  align-items: center;
}

.drop-d-n h5 {
  color: #212121;
  padding-left: 10px;
  padding-top: 7px;
  font-weight: 600;
}

.nearest-dr {
  display: flex;
  width: 100%;
}

.nr-lf {
  width: 70%;
}

.nr-rght img {
  width: 126px;
  height: 71px;
  object-fit: cover;
  border-radius: 13px;
}

.nr-lf h6 {
  color: #1163c3;
  font-size: 14px;
  margin-bottom: 0px;
  padding-bottom: 5px;
}

.nr-lf span {
  color: #7c7c7c;
  font-size: 13px;
}

.nr-lf strong {
  font-size: 13px;
  color: #5773b8;
  font-weight: 400;
  display: block;
  padding-top: 4px;
}

.ellipsis {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*main block starts here*/
.main-block {
  padding: 30px 15px;
}

/*assure strip start here*/

/*assure strip end here*/

/*sidebar css starts here*/

.sdbar {
  position: relative;
}

.nav__trigger {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 2em;
  z-index: 10;
}

.inverse {
  color: white;
}

.nav__menu--right {
  top: 0px;
  position: fixed;
  right: -350px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 102;
  transition: 400ms ease;
  overflow-y: scroll;
  transform: translateX(0px);
}

.nav__menu--right.open {
  height: 100%;
  transform: translateX(-350px);
}

.close-nav {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.close-nav i {
  font-size: 26px;
  color: #b1b1b1;
}

.sd-user-info-user-image img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}

.sidebar-menu {
  padding-top: 2px;
}

.dummyimage img {
  width: 60px;
  height: 60px;
}

.nonlogged-sidebar .user-txt-info {
  padding-top: 18px;
}

.coins-sd-bar {
  border-radius: 7px;
  background: #fdf6d9;
  margin: 10px 10px 0px 10px;
  padding: 10px;
  display: flex;
  border-radius: 6px;
  cursor: pointer;
}

.coins-sd-bar-txt {
  padding-left: 10px;
  padding-top: 3px;
}

.coins-sd-bar-txt h5 {
  color: #393a3e;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.coins-sd-bar-txt strong {
  color: #ff3201;
  font-size: 15px;
  font-weight: 700;

  text-transform: uppercase;
}

.sd-user-info {
  padding: 15px;
  display: flex;
  border-bottom: 1px solid #e9e9e9;
}

.lf-mn-services {
  border-bottom: 1px solid #e9e9e9;
}

.user-txt-info {
  padding-left: 10px;
  padding-top: 4px;
}

.user-txt-info h3 {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.user-txt-info span {
  color: #767676;
  display: block;
  font-size: 12px;
  font-weight: 400;
  word-break: break-word;
  max-width: 151px;
}

.lf-main-menu {
  padding: 15px 6px;
}

.lf-mn-services {
  padding: 12px 15px 0px;
}

.lf-mn-services ul {
  display: flex;
  justify-content: space-between;
}

.lf-mn-services li {
  text-align: center;
}

.lf-mn-services li a span {
  display: block;
  padding: 8px 0px;
}

.lf-mn-services li a {
  text-align: center;
  color: #404347;
  position: relative;
  font-size: 13px;
}

.lf-mn-services li a i {
  position: absolute;
  background: #e5433b;
  width: 20px;
  color: #fff;
  border-radius: 50%;
  font-style: normal;
  font-size: 12px;
  height: 20px;
  right: 6px;
}

.lf-main-menu li {
  padding: 15px;
}

.lf-main-menu li a label {
  width: 30px;
}

.lf-main-menu li a {
  color: #404347;
  font-size: 15px;
  font-weight: 500;
}

.lf-main-menu li:hover {
  background: #f4f4f4;
}

.lf-main-menu li a:hover {
  text-decoration: none;
}

.lf-main-menu li a img {
  margin-right: 10px;
}

.sidebar-bottom {
  border-top: 1px solid #e9e9e9;
}

.sidebar-bottom-content {
  padding: 15px;
  margin-bottom: 20px;
}

.sidebar-bottom-content a {
  border-radius: 30px;
  background: #2a6dba;
  padding: 10px 15px;
  color: #fff;
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}

.sidebar-bottom-content a:hover {
  background: #064d9f;
  text-decoration: none;
}

.sidebar-bottom {
  bottom: 0px;
  padding: 30px 0px;
  width: 100%;
}

.follow-txt {
  text-align: center;
  padding: 20px 0px;
  color: #c0c0c0;
}

.follow-txt strong {
  font-weight: 500;
  font-size: 15px;
}

.follow-txt i {
  height: 1px;
  background: #ccc;
  width: 100%;
  display: block;
}

.follow-txt span {
  padding: 0px 10px;
  display: block;
  margin-top: -12px;
}

.follow-txt span strong {
  background: #fff;
  padding: 0px 10px;
}

.social-control-bar {
  padding: 0px 20px;
}

.social-control-bar ul {
  display: flex;
  justify-content: space-around;
}

.social-control-bar ul li a {
  font-size: 22px;
  display: block;
  color: #808080;
}

.address img {
  margin-left: 3px;
  padding-right: 2px;
}

.logout label img {
  margin-left: -6px;
}

.ft-header {
  font-size: 24px;
  font-weight: 700;
  color: #0451be;
  padding-top: 10px;
  padding-bottom: 25px;
}

.social-icons-ft {
  display: flex;
  padding: 20px 0px;
}

.social-icons-ft li a i {
  font-size: 27px;
}

.social-icons-ft li a {
  color: #505050;
  padding-right: 15px;
}

.social-icons-ft .cs-facebook i,
.social-icons-ft .cs-facebook a:hover {
  color: #0866ff !important;
}
.social-icons-ft .cs-instagram i,
.social-icons-ft .cs-instagram a:hover {
  color: transparent;
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
}
.social-icons-ft .cs-twitter i,
.social-icons-ft .cs-twitter a:hover {
  color: #010101 !important;
}
.social-icons-ft .cs-linkedin i,
.social-icons-ft .cs-linkedin a:hover {
  color: #0a66c2 !important;
}
.social-icons-ft .cs-youtube i,
.social-icons-ft .cs-youtube a:hover {
  color: #ff0000 !important;
}
.social-icons-ft .cs-tiktok i,
.social-icons-ft .cs-tiktok a:hover {
  color: #010101 !important;
}
.social-icons-ft .cs-snapchat i,
.social-icons-ft .cs-snapchat a:hover {
  color: #fffc00 !important;
}
.social-icons-ft .cs-snapchat {
  position: relative;
}
.social-icons-ft .cs-snapchat i::after {
  background: #000;
  width: 20px;
  height: 20px;
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  z-index: -1;
  left: 3px;
}
.social-icons-ft:hover {
  color: initial;
}

.cs-snapchat-sidebar i::after {
  top: 2px !important;
  left: 2px !important;
}

.sd-user-info .cs-facebook i,
.sd-user-info .cs-facebook a:hover {
  color: #0866ff !important;
}
.sd-user-info .cs-instagram i,
.sd-user-info .cs-instagram a:hover {
  color: transparent;
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
}
.sd-user-info .cs-twitter i,
.sd-user-info .cs-twitter a:hover {
  color: #010101 !important;
}
.sd-user-info .cs-linkedin i,
.sd-user-info .cs-linkedin a:hover {
  color: #0a66c2 !important;
}
.sd-user-info .cs-youtube i,
.sd-user-info .cs-youtube a:hover {
  color: #ff0000 !important;
}
.sd-user-info .cs-tiktok i,
.sd-user-info .cs-tiktok a:hover {
  color: #010101 !important;
}
.sd-user-info .cs-snapchat i,
.sd-user-info .cs-snapchat a:hover {
  color: #fffc00 !important;
}
.sd-user-info .cs-snapchat {
  position: relative;
}
.sd-user-info .cs-snapchat i::after {
  background: #000;
  width: 20px;
  height: 20px;
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  z-index: -1;
  left: 0px;
}
.sd-user-info:hover {
  color: initial;
}

.style-ul .cs-facebook i,
.style-ul .cs-facebook a:hover {
  color: #0866ff !important;
}
.style-ul .cs-instagram i,
.style-ul .cs-instagram a:hover {
  color: transparent;
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ) !important;
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ) !important;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ) !important;
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.style-ul .cs-twitter i,
.style-ul .cs-twitter a:hover {
  color: #010101 !important;
}
.style-ul .cs-linkedin i,
.style-ul .cs-linkedin a:hover {
  color: #0a66c2 !important;
}
.style-ul .cs-youtube i,
.style-ul .cs-youtube a:hover {
  color: #ff0000 !important;
}
.style-ul .cs-tiktok i,
.style-ul .cs-tiktok a:hover {
  color: #010101 !important;
}
.style-ul .cs-snapchat i,
.style-ul .cs-snapchat a:hover {
  color: #fffc00 !important;
  position: relative;
}
.style-ul .cs-snapchat {
  position: relative;
}
.style-ul .cs-snapchat i::after {
  background: #000;
  width: 81%;
  height: 71%;
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  z-index: -1;
  left: 4px;
}
.style-ul:hover {
  color: initial;
}

.revmodal .pro-info {
  margin-bottom: 0px;
}

.contact-info-light {
  padding: 15px 0px 0px;
}

.contact-info-light li a,
.contact-info-light li span {
  color: #0451be;
  font-size: 18px;
}

.main-footer {
  margin-top: 15px;
  z-index: 3;
}

.footer-top {
  padding: 110px 0 50px;
}

.contact-info-light li {
  margin-bottom: 15px;
  list-style: none;
}

.contact-info-light li i {
  padding-right: 10px;
}

.main-footer {
  position: relative;
  overflow: visible;
  background-color: #f3f4ff;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  margin-top: 80px;
}

.imp-footer a {
  color: #0451be;
}

.footer-links-bl li {
  padding-bottom: 10px;
  list-style: none;
}

.footer-links-bl li a {
  font-size: 15px;
  color: #231f20;
}

.footer-links-bl li a:hover {
  color: #0451be;
}

.social-icons-ft li a i:hover {
  color: #0451be;
}

.footer-payment {
  display: flex;
  padding: 20px 0px;
}
.footer-payment li img {
  width: 75px;
}
.whatsapp-container {
  background: #0451be;
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
}

.whatsapp-left {
  display: flex;
}

.mob-num img {
  width: 25px;
}

.whatsapp-txt {
  padding-left: 20px;
  color: #fff;
}

.whatsapp-txt h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}

.whatsapp-txt span {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.whatsapp-icon img {
  width: 50px;
}

.whatsapp-right {
  display: flex;
}

.mob-num {
  width: 70%;
  padding-right: 15px;
}

.mob-num input {
  border: 0px;
  border-radius: 45px;
  height: 45px;
  width: 100%;
  padding-left: 90px;
  font-size: 15px;
}

.mob-num img {
  position: absolute;
  top: 8px;
  width: 30px;
  left: 26px;
}

.mob-num span {
  position: absolute;
  top: 12px;
  color: #404344;
  font-size: 15px;
  left: 64px;
}

.cm-orange-btn {
  color: #fff;
  background: linear-gradient(
    0deg,
    rgba(247, 146, 34, 1) 0%,
    rgba(255, 50, 1, 1) 100%
  );
  height: 45px;
  border-radius: 45px;
  padding: 10px 20px;
  font-weight: 600;
}

.cm-orange-btn:hover {
  background: #f79222;
}

.ad-car-dropdown label {
  background: #fff;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brimage {
  width: 16px;
  height: 16px;
}

.active-car-val .ad-car-dropdown {
  padding-left: 42px;
}

.active-car-val .ad-car-dropdown span {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding-top: 7px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
}

.active-car-val .ad-car-dropdown label {
  background: #fff;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 6px;
  left: 10px;
  border-radius: 30px;
  padding-top: 0px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.active-car-val strong {
  color: #9bbfea;
  font-size: 11px;
  font-weight: 400;
  position: absolute;
  top: -7px;
}

.ftlogo-bar {
  position: absolute;
  top: -55px;
  left: 45px;
}

.ftlogo-bar img {
  border-radius: 50%;
  width: 151px;
  height: 151px;
}

.greyC {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
}

.imp-footer {
  padding: 0px 0px 25px;
}

/* Media query starts here */

.cartitems i {
  font-size: 22px;
  color: #3a63b8;
  padding-top: 9px;
  padding-right: 10px;
}

.cartitems i:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  /* background: red; */
  border-radius: 10px;
  margin-left: -4px;
  /* border: 1px solid #fff; */
  margin-top: -3px;
}

.assure-section {
  position: relative;
}

.stars svg {
  fill: #ffba07;
}

@media screen and (max-width: 1330px) {
  .coin-block span {
    padding-top: 2px !important;
    margin-top: 4px;
  }

  .bt-header .nav {
    width: 100%;
  }

  .nvlink-div {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .bt-header .nav-link {
    letter-spacing: initial;
  }

  .right-action-nav ul {
    height: 47px;
  }

  .nvlink-div::-webkit-scrollbar {
    width: 2px;
    height: 3px;
    position: absolute;
  }

  .nvlink-div::-webkit-scrollbar-track {
    background: #d9d9d9;
  }

  .nvlink-div::-webkit-scrollbar-thumb {
    background-color: #979797;
  }

  .nvlink-div::-webkit-scrollbar:vertical {
    display: none;
  }

  .bt-header .nav {
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
  }
}

.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 101;
  margin-left: -20px;
}

.dropdown-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 10;
  transition: 0.3s;
}

.backdrop.show {
  display: block;
  transition: 0.3s;
}

.mob-view {
  display: none;
}

@media screen and (max-width: 1327px) {
  .right-action-nav ul li a {
    padding: 4px 5px;
  }
  .db-layer span {
    font-size: 10px;
  }
}
@media screen and (min-width: 1300px) {
  .overflow-x-auto {
    overflow: hidden;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1219px) {
  .nav-bar-custom-8 {
    max-width: 58%;
    flex: 58%;
  }
  .nav-bar-custom-4 {
    max-width: 42%;
    flex: 42%;
  }
}

@media screen and (max-width: 1048px) {
  .right-action-nav {
    display: none;
  }

  .nav-col {
    max-width: 100%;
  }

  .nvlink-div {
    width: 100%;
  }

  .cal-action {
    display: none !important;
  }

  .mobmenu {
    display: block !important;
    position: absolute;
    right: 10px;
  }

  .right-tp-header ul .search-block input {
    width: 225px;
  }

  .right-tp-header ul .search-block .custom-search-bar {
    width: 225px;
  }

  .mobmenu i {
    font-size: 20px;
  }

  .searchmobile {
    padding-right: 10px;
  }

  .bt-header .nav-link {
    font-size: 13px;
    padding: 14px 7px;
    letter-spacing: initial !important;
  }

  .credmobile {
    padding-right: 10px;
  }
}

@media screen and (max-width: 960px) {
  .serachli {
    display: none;
  }

  .sraction-block {
    justify-content: flex-end !important;
    margin-top: -63px;
  }

  .right-tp-header {
    margin-right: 80px;
  }
}

@media screen and (max-width: 768px) {
  .right-drop-elm .dropdown-menu {
    right: inherit;
  }
  .nav-bar-custom-4 {
    display: none;
  }
  .dsp-view {
    display: none;
  }
  .mob-view {
    display: inline-block;
    padding-right: 12px;
  }

  .mob-view i:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    /* background: red; */
    border-radius: 10px;
    margin-left: -4px;
    /* border: 1px solid #fff; */
    margin-top: -3px;
  }

  .menumobile {
    margin-right: 10px;
  }

  .mobmenu {
    top: 26px;
  }

  .logo {
    width: 120px;
  }
  .logo-bs {
    width: 212px;
  }

  .sraction-block {
    margin-top: 0px;
    border-top: 1px solid #ccc;
  }

  .right-tp-header {
    margin-right: 4px;
    margin-bottom: 2px;
    padding-top: 2px;
  }

  .ad-car-dropdown {
    width: 200px;
  }

  .locli {
    position: absolute;
    left: 9px;
  }

  .cr-loca-popup .cr-location {
    width: 170px;
    padding-left: 0px;
    border: 0px;
  }

  .cr-location span {
    left: -6px;
    top: 2px;
    background: transparent;
  }

  .right-tp-header ul li {
    padding-right: 4px;
  }

  .cr-location strong {
    display: inline-block;
    padding-top: 9px;
  }

  .header-bar .navbar {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar-brand {
    padding-left: 15px;
  }

  .bt-header .nav {
    height: 40px;
  }

  .custom-bt-header .nav {
    height: 40px;
    opacity: 0;
    display: none;
    pointer-events: none;
  }

  .bt-header .nav-link {
    padding: 11px 7px;
  }

  .cr-location i {
    top: 12px;
  }

  .service-block-home li span {
    height: 45px;
  }

  /* body {
        padding-top: 141px;
    } */

  .serachli {
    position: absolute;
    z-index: 100;
    background: #fff;
    padding: 2px;
    left: 0px;
    width: 100vw;
    top: 14px;
  }

  .right-tp-header ul .search-block {
    position: relative;
    padding: 0px 22px;
    margin-left: 16px;
  }

  .close-srch {
    position: absolute;
    display: block !important;
    left: -6px !important;
    font-size: 23px !important;
    top: 9px !important;
  }

  .right-tp-header ul .search-block i {
    left: 37px;
  }

  .right-tp-header ul .search-block input {
    width: 100%;
  }

  .right-tp-header ul .search-block .custom-search-bar {
    width: 100%;
  }

  .searchmobile {
    padding-right: 15px;
  }

  .credmobile {
    padding-right: 17px;
  }

  .assureblick-li {
    top: 84px;
  }

  .assure-mobile {
    display: block !important;
    position: relative;
  }

  .nvlink-div::-webkit-scrollbar {
    width: 2px;
    height: 0px;
    position: absolute;
  }
}

@media screen and (max-width: 500px) {
  .right-drop-elm .car-loc-dropdown:before {
    right: initial;
    left: auto;
  }

  .footer-top {
    padding: 110px 0 25px;
  }

  .footer-links-bl li a {
    font-size: 14px;
  }

  .search-suggestion {
    top: 51px;
    width: 99%;
  }

  .wp-sb {
    width: 100%;
    margin-top: 13px;
  }

  .cm-orange-btn {
    width: 100% !important;
  }

  .nr-rght img {
    width: 100px;
  }

  .header-all {
    font-size: 25px !important;
  }

  .whatsapp-txt h5 {
    font-size: 20px;
  }

  .cm-orange-btn {
    width: 100%;
  }

  .whatsapp-txt span {
    display: none;
  }

  .whatsapp-left {
    padding-bottom: 10px;
  }

  .whatsapp-right {
    flex-wrap: wrap;
  }

  .mob-num {
    width: 100%;
    padding-right: 0px;
  }

  .wp-sb {
    width: 100%;
  }

  .whatsapp-txt {
    margin-bottom: 10px;
  }

  .wp-sb {
    margin-top: 13px;
  }

  .assureblick-li {
    top: 8px;
  }

  .serachli {
    top: 7px;
  }

  .mob-links {
    padding: 16px 2px 0px;
  }

  .cr-location i {
    right: -33px;
    top: 12px;
  }

  .mobmenu {
    top: 13px;
    right: 7px;
  }

  .mobmenu-checkout {
    top: -10px !important;
  }

  .navbar-brand {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
  }

  .ad-car-dropdown {
    max-width: 180px;
  }
  .glare-animation {
    max-width: 135px !important;
  }
  .car-loc-dropdown {
    width: 95vw;
  }

  .active-car-val strong {
    font-size: 10px;
  }

  .right-tp-header {
    margin-bottom: 3px;
    padding-top: 3px;
    margin-right: 6px;
  }

  .brimage {
    top: 9px;
  }

  .active-car-val .ad-car-dropdown span {
    font-size: 12px;
  }

  .cr-loca-popup .cr-location {
    width: auto;
    max-width: 123px;
  }

  .cr-location span {
    font-size: 11px;
    display: block;
    white-space: nowrap;
    letter-spacing: -0.6px;
    top: 5px;
  }

  .cr-location strong {
    padding-top: 9px;
    font-size: 12px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110%;
    min-width: 80px;
  }

  .subHead-all {
    padding-bottom: 3px !important;
  }

  .greyC {
    text-align: center !important;
  }

  .bt-header {
    padding: 0px 2px;
  }

  .mobmenu i {
    font-size: 21px;
  }

  .cartitems i {
    font-size: 21px;
    color: #333333;
    padding-top: 10px;
    padding-right: 17px;
  }

  .cartitems {
    position: absolute;
    top: 10px;
    right: 123px;
  }
}

.ob-car-choose-section-bounce {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
